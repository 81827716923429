<template>
  <div>
    <v-container>
      <v-row @click="startReview()">
        <v-col>
          <v-card color="#69B8B3" dark class="pa-4 pl-0">
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="subtitle-1"> Give Review </v-card-title>
              </div>

              <v-avatar class="ma-3" size="30" tile>
                <v-icon >mdi-star</v-icon>
              </v-avatar>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row @click="selectOption()">
        <v-col cols="12">
          <v-card color="#037171" dark class="pa-4 pl-0">
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="subtitle-1"> Check Review </v-card-title>
              </div>

              <v-avatar class="ma-3" size="30" tile>
                <v-icon>mdi-checkbox-marked-outline</v-icon>
              </v-avatar>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ReviewDataService } from "../../services/DataServiceiReview";

const reviewDataService = new ReviewDataService();

export default {
  data() {
    return {};
  },

  mounted() { 
    this.countTrack()
  },

  methods: {
    startReview() {
      this.$router.push({
        name: "ireviewCategoryOption",
        params: { redirect: "giveReview" },
      });
    },

    selectOption() {
      this.$router.push({
        name: "ireviewBrands",
        params: { redirect: "giveReview" },
      });
    },

    countTrack() {
      reviewDataService.countTrack(); // Need to replace with google analytics.
    }
  },
};
</script>