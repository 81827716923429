import axios from 'axios'
import Util from './Util'

var API_URL = process.env.VUE_APP_REVIEW_URL

export class ReviewDataService {
    getQuestions () {
      const url = `${API_URL}/questions/getQuestions`
      return axios.post(url).then(response => response.data)
    }

    getSegments(data) {
        const url = `${API_URL}/brands/getSegmentsByCountry`
        return axios.post(url, data, Util.setupHeaders()).then(response => response.data)
    }

    getCategories(data) {
        const url = `${API_URL}/brands/getCategoriesBySegment`
        return axios.post(url, data, Util.setupHeaders()).then(response => response.data)
    }

    getSubcategories(data) {
        const url = `${API_URL}/brands/getSubByCategories`
        return axios.post(url, data, Util.setupHeaders()).then(response => response.data)
    }

    getBrands(data) {
        const url = `${API_URL}/brands/getBrandsBySub`
        return axios.post(url, data, Util.setupHeaders()).then(response => response.data)
    }

    saveQuestionData(data) {
        const url = `${API_URL}/questions/saveData`
        return axios.post(url, data).then(response => response.data)
    }

    getReviewInfo(reviewCode){
        const url = `${API_URL}/questions/getReviewInfo`
        return axios.post(url, reviewCode).then(response => response.data)
    }

    startReview(reviewCode){
        const url = `${API_URL}/questions/startReview`
        return axios.post(url, reviewCode).then(response => response.data)
    }

    getBrandName(data) {
        const url = `${API_URL}/brands/getBrandName`
        return axios.post(url, data,  Util.setupHeaders()).then(response => response.data)
    }
    
    saveResponse(data) {
        const url = `${API_URL}/response/saveResponse`
        return axios.post(url, data, Util.setupHeaders()).then(response => response.data)
    }

    getReviewData(data) {
        const url = `${API_URL}/response/getResponseData`
        return axios.post(url, data, Util.setupHeaders()).then(response => response.data)
    }

    getSearchData(data) {
        const url = `${API_URL}/response/getSearchData`
        return axios.post(url, data, Util.setupHeaders()).then(response => response.data)
    }

    findBrand(searchCriteria){
        const url = `${API_URL}/brands/findBrand`
        return axios.post(url, searchCriteria, Util.setupHeaders())
    }

    detectText(data) {
        const url = `${API_URL}/response/detectText`
        return axios.post(url, data, Util.setupHeaders()).then(response => response.data)
    }

    countTrack() {  // Need to replace with google analytics.
        const url = `${API_URL}/data/trackCount`
        return axios.post(url, {}, Util.setupHeaders()).then(response => response.data)
    }
}